<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Detalle de solicitud {{ document.id.substr(0, 6).toUpperCase() }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings"
               :key="$vs.rtl">
      <div class="p-6">
        <!--Approbe or deny-->
        <div class="vx-row mb-5" v-if="document.state === 'hold' && availableForChangedState">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-button :disabled="progress" @click="changedState('success')" class="w-full" color="success"
                       type="filled">Aprobar
            </vs-button>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-button :disabled="progress" @click="changedState('rejected')" class="w-full" color="danger"
                       type="filled">Denegar
            </vs-button>
          </div>
        </div>
        <!--End-->
        <!--Observation-->
        <div>
          <vs-textarea :disabled="!(document.state === 'hold')" label="Observación"
                       v-model="document.observation"></vs-textarea>
        </div>
        <!--Motive-->
        <div v-if="document.motive">
          <vs-textarea disabled="true" label="Motivo" :value="document.motive"></vs-textarea>
        </div>
        <!--Warehouse-->
        <div class="mt-5">
          <vs-input class="w-full" label="Almacén de solicitud" disabled="true"
                    :value="document.warehouse.name"></vs-input>
        </div>
        <!--user-->
        <div class="mt-5">
          <vs-input class="w-full" label="Usuario que registró solicitud" disabled="true"
                    :value="document.user.displayName"></vs-input>
        </div>
        <!--Products-->
        <div class="mt-8">
          <h6>Productos</h6>
          <vs-table class="mt-5" :data="document.products">

            <template slot="thead">
              <vs-th>Nombre</vs-th>
              <vs-th>SKU</vs-th>
              <vs-th>Categoría</vs-th>
              <vs-th>Marca</vs-th>
              <vs-th>Cantidad</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.name">
                  <p>{{ tr.name }}</p>
                </vs-td>

                <vs-td :data="tr.sku">
                  <p> {{ tr.sku }}</p>
                </vs-td>

                <vs-td :data="tr.category">
                  <p>{{ tr.category.name }}</p>
                </vs-td>

                <vs-td :data="tr.brand">
                  <p>{{ tr.brand.name }}</p>
                </vs-td>

                <vs-td :data="tr.quantity">
                  <template>
                    <vs-input disabled="true" min="1" style="width: 80px" type="number"
                              v-model.number="tr.quantity"/>
                  </template>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"

import {db, FieldValue, auth} from '@/firebase/firebaseConfig'

const _ = require('lodash')
export default {
  props: {
    data: {
      type: Object,
      default: () => {
      }
    },
    isSidebarActive: {
      type: Boolean,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar
  },
  mixins: [trimString],
  data() {
    return {
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      document: {
        products: [],
        warehouse: {},
        user: {},
        id: ''
      },
      progress: false,
      availableForChangedState: false
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      this.document = _.cloneDeep(this.data)
      this.availableForChangedState = false
      const user = this.document.directedTo.find((u) => u.uid === auth.currentUser.uid)
      if (user) {
        this.availableForChangedState = true
      }
      this.$validator.reset()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Chage state in firestore
     * @param state
     * @returns {Promise<void>}
     */
    async changedState(state) {
      try {
        this.progress = true
        await db.collection('requests').doc(this.document.id).update({
          state: state,
          updatedAt: FieldValue.serverTimestamp(),
          lastUser: auth.currentUser.uid,
          observation: this.document.observation || ''
        })
        const obj = {
          ...this.document,
          state: state
        }
        if (state === 'success') {
          this.$vs.notify({
            color: 'success',
            title: 'Solicitudes',
            text: 'Solicitud aprobada correctamente.'
          })
        } else {
          this.$vs.notify({
            color: 'success',
            title: 'Solicitudes',
            text: 'Solicitud denegada correctamente.'
          })
        }
        this.$emit('update', obj)
        this.$emit('closeSidebar')
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.progress = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 950px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
