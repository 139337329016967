<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Registro de solicitud de traslado</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Motive -->
        <vs-textarea @blur="document.motive= trimString(document.motive)" label="Motivo*" v-model="document.motive"
                     class="w-full" name="motive"
                     v-validate="'required'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('motive')">{{ errors.first('motive') }}</span>


        <!--Warehouse-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Almacén de solicitud*</label>
          <v-select label="name" :options="availableWarehouses" :clearable="true"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.warehouse" class="mb-4 md:mb-0" name="warehouse" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('warehouse')">{{ errors.first('warehouse') }}</span>
        </div>
        <!--End-->

        <!--Directed to-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Dirigida a*</label>
          <v-select multiple label="displayName" :options="users" :clearable="true"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="document.directedTo" class="mb-4 md:mb-0" name="directedTo" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('directedTo')">{{ errors.first('directedTo') }}</span>
        </div>
        <!--End-->

        <hr class="mt-5">

        <!--Products-->
        <div class="mt-5">
          <label class="text-sm opacity-75">Listado de productos</label>
          <v-select :options="allProducts" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="selectedProduct" class="mb-4 md:mb-0" name="product" v-validate="'required'"/>
        </div>
        <!--End-->

        <vs-button class="mt-5" @click="addProduct">Agregar</vs-button>

        <!--Added products-->
        <vs-table class="mt-5" style="padding-bottom: 14rem" :data="addedProducts">

          <template slot="thead">
            <vs-th>Nombre</vs-th>
            <vs-th>SKU</vs-th>
            <vs-th>Categoría</vs-th>
            <vs-th>Marca</vs-th>
            <vs-th>Cantidad</vs-th>
            <vs-th>Eliminar</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="tr.name">
                <p>{{ tr.name }}</p>
              </vs-td>

              <vs-td :data="tr.sku">
                <p> {{ tr.sku }}</p>
              </vs-td>

              <vs-td :data="tr.category">
                <p>{{ tr.category.name }}</p>
              </vs-td>

              <vs-td :data="tr.brand">
                <p>{{ tr.brand.name }}</p>
              </vs-td>

              <vs-td :data="tr.quantity">
                <template>
                  <vs-input min="1" style="width: 80px" type="number" v-model.number="tr.quantity"/>
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon class="ml-2" icon="TrashIcon"
                              svgClasses="w-5 h-5 hover:text-primary stroke-current"
                              @click.stop="deleteProduct(tr)"/>
              </vs-td>

            </vs-tr>
          </template>
        </vs-table>
        <!---->
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="confirmSubmit" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import vSelect from 'vue-select'

import {db, FieldValue, storage} from '@/firebase/firebaseConfig'

const _ = require('lodash')
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    warehouses: {
      type: Array,
      required: true
    },
    products: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data() {
    return {
      document: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      selectedProduct: {},
      addedProducts: [],
      allProducts: [],
      availableWarehouses: []
    }
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        this.document = {}
        this.selectedProduct = {}
        this.addedProducts = []
        this.allProducts = _.cloneDeep(this.products)
        this.availableWarehouses = []
        this.availableWarehouses = this.warehouses.filter((w) => w.warehouseManager.uid === this.user.uid)
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Pre submit
     * */
    async confirmSubmit() {
      const result = await this.$validator.validateAll()
      if (result) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: '¡Atención!',
          text: `Luego de guardar la solicitud no podrás editarla. ¿Deseas guardar la solicitud?`,
          accept: this.submitData,
          cancel: null,
          parameters: null,
          acceptText: 'Guardar',
          cancelText: 'Cancelar'
        })
      }
    },
    /**
     * Save data in firestore
     */
    async submitData() {
      try {
        this.progress = true
        const result = await this.$validator.validateAll()
        if (result) {
          if (this.validateQuantityNoZero()) {
            this.progress = true
            let obj = {
              ...this.document,
              products: [...this.addedProducts],
              user: this.user,
              state: 'hold'
            }
            // Create order
            const doc = await db.collection('requests').add({
              ...obj,
              createdAt: FieldValue.serverTimestamp()
            })
            obj.id = doc.id
            obj.createdAt = new Date()
            // End
            // Update request
            const responsePdf = await this.generatePDF(obj.id)
            setTimeout(async () => {
              const url = await this.getPdfUrl(responsePdf.id, obj.id)
              await db.collection('requests').doc(obj.id).update({
                pdfId: responsePdf.id,
                pdfUrl: url
              })
              obj.pdfUrl = url
              window.open(obj.pdfUrl, '_blank')
              // Update request
              this.$emit('closeSidebar')
              this.$emit('add', obj)
              this.progress = false
              this.$vs.notify({
                color: 'success',
                title: 'Solicitudes',
                text: 'Solicitud registrada correctamente.'
              })
              this.progress = false
            }, 5000)
          } else {
            this.$vs.notify({
              color: 'danger',
              title: 'Error',
              text: 'Cantidades no pueden ser igual a 0'
            })
            this.progress = false
          }
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      }
    },
    /**
     * Validate that no product has stock 0
     * */
    validateQuantityNoZero() {
      let flag = 1
      this.addedProducts.forEach((p) => {
        if (p.quantity <= 0) {
          flag = 0
        }
      })
      return flag === 1
    },
    /**
     * Add product
     */
    async addProduct() {
      if (this.selectedProduct.id) {
        this.addedProducts.push({
          ...this.selectedProduct,
          quantity: 1
        })
        const indexProduct = this.allProducts.findIndex((p) => p.id === this.selectedProduct.id)
        this.allProducts.splice(indexProduct, 1)
        this.selectedProduct = {}
      }
    },
    /**00
     * Delete product
     */
    deleteProduct(product) {
      this.allProducts.push(product)
      const indexProduct = this.addedProducts.findIndex((p) => p.id === product.id)
      this.addedProducts.splice(indexProduct, 1)
    },
    /**
     * Generate PDF
     */
    async generatePDF(id) {
      const products = []
      this.addedProducts.forEach((p) => {
        products.push({
          name: p.name,
          sku: p.sku,
          quantity: p.quantity
        })
      })
      const response = await this.$http.post('https://api.pdfmonkey.io/api/v1/documents', {
        document: {
          document_template_id: '8E0604F2-9D16-4FED-953B-2BD8C253A4FC',
          payload: {
            number: id.substr(0, 6).toUpperCase(),
            createdAt: new Date().getDate() + "/" + (new Date().getMonth() + 1) + "/" + new Date().getFullYear(),
            user: {
              name: this.user.displayName
            },
            warehouse: {
              name: this.document.warehouse.name
            },
            products
          },
          status: "pending"
        }
      }, {
        headers: {
          "Authorization": "Bearer iVtju3Mvy6c2gzhGWerf"
        }
      })
      return response.data.document
    },
    /**
     * Get URL of monkey
     */
    async getPdfUrl(id, docId) {
      const response = await this.$http.get(`https://api.pdfmonkey.io/api/v1/documents/${id}`, {
        headers: {
          "Authorization": 'Bearer iVtju3Mvy6c2gzhGWerf'
        }
      })
      // save in firebase storage
      const responsePdf = await window.fetch(response.data.document.download_url)
      const docNameFinal = docId.substr(0, 6).toUpperCase() + ".pdf"
      const storageRef = storage.ref(`requests/${docNameFinal}`)
      const blob = await responsePdf.blob()
      const snapshot = await storageRef.put(blob)
      const url = await snapshot.ref.getDownloadURL()
      return url
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 950px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
